import React from "react";
import {Routes, Route} from "react-router-dom";
import LandingView from "./views/LandingView";
import BottomFixedFooter from "./components/BottomFixedFooter";
import TopFixedNavigationBar from "./components/TopFixedNavigationBar";

export default function App() {
    return (
        <React.Fragment>
            <TopFixedNavigationBar />

            <Routes>
                <Route path="/">
                    <Route index element={<LandingView/>}/>
                </Route>
            </Routes>

            <BottomFixedFooter />
        </React.Fragment>
    );
}