import React from "react";

export default function MusicBarAnimation() {

    return (
        <React.Fragment>
            <div className="now playing">
                <span className="bar n1">A</span>
                <span className="bar n2">B</span>
                <span className="bar n3">c</span>
                <span className="bar n4">D</span>
                <span className="bar n5">E</span>
                <span className="bar n6">F</span>
                <span className="bar n7">G</span>
                <span className="bar n8">H</span>
            </div>
        </React.Fragment>
    )
}