import React from "react";
import EmptyDivider from "../components/EmptyDivider";
import PerformanceEventsPresenter from "../components/PerformanceEventsPresenter";
import ShoutcastStreamPlayer from "../components/ShoutcastStreamPlayer";
import ApiFetch from "../utils/ApiFetch";
import Objects from "../utils/Objects";
import {WebLinkCollection} from "../utils/WebLinkCollection";
import EmbeddedYoutubeVideos from "../components/EmbeddedYoutubeVideos";
import SocialMediaIconsContainer from "../components/SocialMediaIconsContainer";
import Arrays from "../utils/Arrays";

const NO_VIDEO_PLAYBACK_CLASS_NAMES = "container-fluid text-white landing-view-header-background img-background shadow-lg";
const VIDEO_PLAYBACK_CLASS_NAMES = "video-background-container purple-background text-white shadow-lg";

const LIVE_STREAM_PLAYBACK_VIDEO = "/video/4440816-hd_1280_720_50fps.mp4";
const DJ_AVATAR_IMAGE = "/img/background/IMG_20201023_095832.jpg";

const SHOUTCAST_SERVER_PROBE_RELATIVE_URL = "/shoutcast-server/probe";
const PERFORMANCE_EVENT_SCHEDULES_ON_SCHEDULE_RELATIVE_URL = "/performance-event-schedules/on-schedule";

function LandingHeaderContainer() {
    let interval = null;

    const [useVideoBackground, setUseVideoBackground] = React.useState(false);
    const [eventSchedule, setEventSchedule] = React.useState(null);
    const [enableShoutcastStreamPlayer, setEnableShoutcastStreamPlayer] = React.useState(false);

    const getHeaderClassNames = function (toggle) {
       return (toggle) ? VIDEO_PLAYBACK_CLASS_NAMES : NO_VIDEO_PLAYBACK_CLASS_NAMES;
    }

    const probeShoutcastServerStream = function () {
        if (!enableShoutcastStreamPlayer) {
            new ApiFetch()
                .get(SHOUTCAST_SERVER_PROBE_RELATIVE_URL)
                .then((response) => {
                    const responseBody = ApiFetch.getResponseBody(response);
                    const available = Objects.isNotNull(responseBody) && responseBody.data;

                    setEnableShoutcastStreamPlayer(available);
                    setUseVideoBackground(available);
                })
                .catch(() => {
                    setEnableShoutcastStreamPlayer(false);
                    setUseVideoBackground(false);
                });
        }
    }

    const fetchEventSchedulesOnSchedule = function () {
       new ApiFetch()
           .get(PERFORMANCE_EVENT_SCHEDULES_ON_SCHEDULE_RELATIVE_URL)
           .then((response) => {
                const responseBody = ApiFetch.getResponseBody(response);

                if (responseBody && responseBody.data) {
                    const eventSchedules = responseBody.data;
                    setEventSchedule(Arrays.hasItems(eventSchedules) ? eventSchedules[0] : null);
                } else {
                    setEventSchedule(null);
                }
            });
    }

    const initEffect = function () {
        fetchEventSchedulesOnSchedule();
        probeShoutcastServerStream();

        if (Objects.isNull(interval)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            interval = setInterval(() => {
                fetchEventSchedulesOnSchedule();
                probeShoutcastServerStream();
            }, 30000);
        }
    }

    React.useEffect(initEffect, []);

    return (
        <React.Fragment>
            <header className={getHeaderClassNames(useVideoBackground)}>
                {useVideoBackground && (
                    <React.Fragment>
                        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
                            <source src={LIVE_STREAM_PLAYBACK_VIDEO} type="video/mp4"/>
                            Your browser does not support the video player.
                        </video>
                    </React.Fragment>
                )}

                <div className="content">
                    <EmptyDivider degree={3}/>

                    <LandingHeaderLegend
                        showAvatarImage={!enableShoutcastStreamPlayer} />

                    {enableShoutcastStreamPlayer && (
                        <React.Fragment>
                            <EmptyDivider />
                            <ShoutcastStreamPlayer eventSchedule={eventSchedule} />
                        </React.Fragment>
                    )}

                    {!enableShoutcastStreamPlayer && (
                        <React.Fragment>
                            <div className="container text-center justify-content-center">
                                <SocialMediaIconsContainer/>

                                <EmptyDivider />

                                <a href={WebLinkCollection.mixCloudPage} className="btn btn-outline-light shadow-lg m-2"
                                   title="Listen on Mixcloud" role="button" target="_blank" rel="noreferrer">
                                    <span><i className="bi bi-headphones me-2"/>Listen on Mixcloud</span>
                                </a>

                                <EmptyDivider />
                            </div>
                        </React.Fragment>
                    )}

                    <EmptyDivider degree={2}/>
                </div>
            </header>
        </React.Fragment>
    )
}

function LandingHeaderLegend({showAvatarImage = true}) {
    return (
        <React.Fragment>
            {showAvatarImage && (
                <React.Fragment>
                    <div className="container text-center justify-content-center">
                        <EmptyDivider/>

                        <div>
                            <img src={DJ_AVATAR_IMAGE}
                                 className="rounded-circle img-fluid shadow-lg"
                                 alt="DJ Tsepo Mngomezulu Avatar" style={{width: "90px", height: "90px"}}/>
                        </div>

                        <EmptyDivider degree={2}/>
                    </div>
                </React.Fragment>
            )}

            <div className="container text-center">
                <p>
                    <span className="badge purple-background m-1 jiggle-animation">GOOD MUSIC</span>
                    <span className="badge purple-background m-1 jiggle-animation">GOOD VIBES</span>
                    <span className="badge purple-background m-1 jiggle-animation">GOOD PEOPLE</span>
                </p>
            </div>
        </React.Fragment>
    )
}

export default function LandingView() {

    return (
        <React.Fragment>
            <LandingHeaderContainer />
            <PerformanceEventsPresenter />
            <EmbeddedYoutubeVideos />
        </React.Fragment>
    )
}