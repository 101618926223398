import React from "react";
import EmptyDivider from "./EmptyDivider";
import HeaderDivider from "./HeaderDivider";
import ApiFetch from "../utils/ApiFetch";
import LoadingProgressAnimation from "./LoadingProgressAnimation";
import Arrays from "../utils/Arrays";

const FEATURED_YOUTUBE_VIDEOS_RELATIVE_URL = "/featured-youtube-videos/effective";

function FeaturedYouTubeVideoEmbed({featuredYouTubeVideo}) {

    return (
        <React.Fragment>
            <div className="container p-4">
                <h1>{featuredYouTubeVideo.title}</h1>

                <span className="badge darker-purple-background text-white m-1 text-uppercase shadow">
                    {featuredYouTubeVideo.genre}
                </span>

                <span className="badge bg-dark text-white m-1 text-uppercase shadow">
                    {featuredYouTubeVideo.releasedOn}
                </span>

                <EmptyDivider/>

                <p className="font-size-90-pct">{featuredYouTubeVideo.description}</p>

                <EmptyDivider/>

                <div className="d-block responsive-youtube-video-embed">
                    <iframe className="shadow-lg rounded" allowFullScreen={true}
                            src={featuredYouTubeVideo.youTubeVideoEmbedSource}
                            title={featuredYouTubeVideo.title} frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"></iframe>
                </div>

                <EmptyDivider degree={2}/>
            </div>
        </React.Fragment>
    )
}

export default function EmbeddedYoutubeVideos() {

    const [featuredYouTubeVideos, setFeaturedYouTubeVideos] = React.useState([]);
    const [displayLoadingProgress, setDisplayLoadingProgress] = React.useState(false);

    const fetchFeaturedYouTubeVideos = function () {
        setDisplayLoadingProgress(true);

        new ApiFetch()
            .get(FEATURED_YOUTUBE_VIDEOS_RELATIVE_URL)
            .then((response) => {
                const responseBody = ApiFetch.getResponseBody(response);

                if (responseBody && responseBody.data) {
                    setFeaturedYouTubeVideos(responseBody.data);
                } else {
                    setFeaturedYouTubeVideos([]);
                }

                setDisplayLoadingProgress(false);
            })
    }

    const initEffect = function () {
        fetchFeaturedYouTubeVideos();
    }

    React.useEffect(initEffect, []);

    return (
        <React.Fragment>
            <div id="featured-youtube-videos">
                <HeaderDivider
                    title="Featured Mixes on YouTube"
                    description="Checkout the latest prepared DJ mixes from the YouTube channel"
                    iconStyle="youtube"/>

                <div className="container-fluid so-white-pattern-bg text-dark text-center">
                    <EmptyDivider/>

                    {displayLoadingProgress && (
                        <React.Fragment>
                            <LoadingProgressAnimation/>
                        </React.Fragment>
                    )}

                    {Arrays.hasItems(featuredYouTubeVideos) && featuredYouTubeVideos.map((featuredYouTubeVideo) => {
                        const key = featuredYouTubeVideo.id;

                        return (
                            <React.Fragment key={key}>
                                <FeaturedYouTubeVideoEmbed featuredYouTubeVideo={featuredYouTubeVideo} />
                            </React.Fragment>
                        )
                    })}

                    <EmptyDivider degree={2}/>
                </div>
            </div>
        </React.Fragment>
    )
}