import axios from "axios";
import Strings from "./Strings";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const CONTENT_TYPE = "application/json; charset=utf-8";

const formatRelativePath = function (relativePath) {
    if (Strings.isNotBlank(relativePath)) {
        return relativePath.startsWith("/") ? relativePath : ("/" + relativePath);
    }

    return null;
}

const performGetAsync = (relativePath) => {
    return axios.get(API_BASE_URL + formatRelativePath(relativePath), {
        headers: {
            'Content-Type': CONTENT_TYPE
        },
        data: {} // done so the content-type header is not removed by axios - strange shit!!!
    });
}

const performPostAsync = (relativePath, requestBody) => {
    return axios.post(API_BASE_URL + formatRelativePath(relativePath), requestBody, {
        headers: {
            'Content-Type': CONTENT_TYPE
        }
    });
}

export default class ApiFetch {

    static getResponseBody(response) {
        return (response.status === 200 && response.data) ? response.data : null;
    }

    get(relativePath) {
        return performGetAsync(relativePath);
    }

    post(relativePath, requestBody) {
        return performPostAsync(relativePath, requestBody);
    }
}