import React from "react";
import EmptyDivider from "./EmptyDivider";
import Strings from "../utils/Strings";

export default function HeaderDivider({title, description, iconStyle}) {

    const getIconStyle = function (icon) {
        return Strings.format("bi bi-{0} me-2", icon);
    }

    return (
        <React.Fragment>
            <div className="container-fluid darker-purple-background text-white text-center">
                <EmptyDivider degree={2}/>

                <div className="container">
                    <h1>
                        <span className={getIconStyle(iconStyle)}/>
                        <span>{title}</span>
                    </h1>

                    <p>{description}</p>
                </div>

                <EmptyDivider/>
            </div>
        </React.Fragment>
    )

}