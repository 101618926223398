import SocialMediaIcon from "./SocialMediaIcon";
import React from "react";
import {WebLinkCollection} from "../utils/WebLinkCollection";

export default function SocialMediaIconsContainer() {

    return (
        <React.Fragment>
            <div className="container text-center">

                <SocialMediaIcon title="Join the WhatsApp Community"
                                 type="whatsapp"
                                 url={WebLinkCollection.whatsAppCommunityGroup} />

                <SocialMediaIcon title="Catch me live on TikTok"
                                 type="tiktok"
                                 url={WebLinkCollection.tiktokLivePage} />

                <SocialMediaIcon title="Follow the YouTube Channel"
                                 type="youtube"
                                 url={WebLinkCollection.youTubeChannel} />
            </div>
        </React.Fragment>
    )
}