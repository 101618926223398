import EmptyDivider from "./EmptyDivider";
import React from "react";
import SocialMediaIconsContainer from "./SocialMediaIconsContainer";
import {WebLinkCollection} from "../utils/WebLinkCollection";

export default function BottomFixedFooter() {

    return (
        <React.Fragment>
            <footer>
                <div className="container-fluid text-center memphis-mini-dark-pattern-bg text-white">
                    <EmptyDivider degree={1}/>

                    <div className="container">
                        <div className="row flex-grow-1">

                            <div className="col-sm p-4">
                                <p>For private bookings or invitations to events, just drop me an email.</p>

                                <a href={WebLinkCollection.emailAddress} className="btn btn-sm btn-outline-light"
                                   title="Drop me an email" target="_blank" rel="noreferrer">
                                    <i className="bi bi-postcard-heart-fill" />
                                    <span>&nbsp;Drop me an email</span>
                                </a>

                                <EmptyDivider degree={1}/>
                            </div>

                            <div className="col-sm p-4">
                                <p>Let's connect!</p>
                                <SocialMediaIconsContainer />
                                <EmptyDivider degree={1}/>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="container">
                        <span className="font-size-90-pct">
                            <span>Copyright© 2023 <strong>Tsepo Mngomezulu™</strong>. </span>
                            <span>All rights reserved.</span>
                            <br />
                            <span>Website developed by <strong>Tsepo Maleka</strong>.</span>
                        </span>
                    </div>

                    <EmptyDivider degree={2}/>
                </div>
            </footer>
        </React.Fragment>
    )
}