import React from 'react';
import EmptyDivider from "./EmptyDivider";

export default function LoadingProgressAnimation() {

    return (
        <React.Fragment>
            <div className="container text-center justify-content-center p-4">
                <img src="/img/gif/Rocket.gif" alt="Loading, please wait..." />
                <EmptyDivider />
                <p>Doing great things, please wait...</p>
            </div>
        </React.Fragment>
    )
}