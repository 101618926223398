import React from 'react';
import EmptyDivider from "./EmptyDivider";
import Strings from "../utils/Strings";
import Objects from "../utils/Objects";
import ApiFetch from "../utils/ApiFetch";
import Arrays from "../utils/Arrays";
import HeaderDivider from "./HeaderDivider";

const FALLBACK_BANNER_IMAGE_SOURCE = "/img/background/dj-8543998_1920.png";

const EFFECTIVE_PERFORMANCE_EVENT_SCHEDULES_RELATIVE_URL = "/performance-event-schedules/effective";

function PerformanceEventDates({eventSchedule}) {

    const extractDateOnly = function (dateTimeText) {
        const [day, month, year] = dateTimeText.split(' ').slice(0, 3);
        return Strings.format("{0} {1} {2}", day, month, year).toUpperCase();
    }

    const extractTimeOnly = function (dateTimeText) {
        const time = dateTimeText.split(' ')[3];
        const [hours, minutes] = time.split(':');

        return Strings.format("{0}:{1}", hours, minutes);
    }

    return (
        <React.Fragment>
            <div className="row flex-grow-1">
                <div className="col-md-6 col">
                    <h5 className="pt-1 d-md-flex">

                        {eventSchedule.onSchedule && (
                            <React.Fragment>
                                <span className="badge bg-light text-dark shadow m-1">
                                    <i className="bi bi-calendar-check m-2"/>
                                    <span>HAPPENING NOW</span>
                                </span>

                                <span className="badge bg-dark text-white shadow m-1">
                                    <i className="bi bi-clock me-2"/>
                                    <span>SINCE {extractTimeOnly(eventSchedule.nextScheduleStartsAt)}</span>
                                </span>
                            </React.Fragment>
                        )}

                        {!eventSchedule.onSchedule && (
                            <React.Fragment>
                                <span className="badge bg-light text-dark shadow m-1">
                                    <i className="bi bi-calendar-check m-2 ms-0"/>
                                    <span>{extractDateOnly(eventSchedule.nextScheduleStartsAt)}</span>
                                </span>

                                <span className="badge bg-dark text-white shadow m-1">
                                    <i className="bi bi-clock me-2"/>
                                    <span>{extractTimeOnly(eventSchedule.nextScheduleStartsAt)} SAST</span>
                                </span>
                            </React.Fragment>
                        )}
                    </h5>
                </div>
            </div>
        </React.Fragment>
    )
}

function EventScheduleDetailsPresenter({eventSchedule}) {

    const getBannerImageSourceOrDefault = function (eventSchedule) {
        return (eventSchedule && eventSchedule.bannerImageSource)
            ? eventSchedule.bannerImageSource : FALLBACK_BANNER_IMAGE_SOURCE;
    }

    const bannerImageStyle = {
        backgroundImage: "url(" + getBannerImageSourceOrDefault(eventSchedule) + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "scroll",
        minHeight: "170px",
    }

    return (
        <React.Fragment>
            <EmptyDivider/>

            <div className="row flex-grow-1 justify-content-around">
                <div className="col-md-6 pe-4 ps-4">
                    <div className="d-block h-100 img-background rounded shadow-lg"
                         style={bannerImageStyle}/>
                </div>

                <div className="col-md-6 text-align-left p-2">
                    {eventSchedule.onSchedule && (
                        <React.Fragment>
                            <span className="badge purple-background text-light me-2">
                                <i className="bi bi-activity me-2"/>
                                LIVE NOW
                            </span>
                        </React.Fragment>
                    )}

                    {!eventSchedule.onSchedule && (
                        <React.Fragment>
                            <span className="badge bg-light text-dark me-2">
                                <i className="bi bi-activity me-2"/>
                                UPCOMING LIVE EVENT
                            </span>
                        </React.Fragment>
                    )}

                    <h1 className="mt-1">{eventSchedule.title}</h1>

                    <PerformanceEventDates eventSchedule={eventSchedule}/>

                    <EmptyDivider/>

                    <p>{eventSchedule.description}</p>

                    {eventSchedule.onSchedule && (
                        <React.Fragment>
                            <EmptyDivider/>

                            <a href={eventSchedule.venueLocation} title={eventSchedule.venueName}
                               target="_blank" rel="noreferrer" className="btn btn-lg btn-outline-dark shadow">
                                <i className="bi bi-play-circle me-2"/>
                                <span>Listen Now</span>
                            </a>
                        </React.Fragment>
                    )}

                    <EmptyDivider/>
                </div>
            </div>

            <EmptyDivider/>
        </React.Fragment>
    )
}

export default function PerformanceEventsPresenter() {
    let interval = null;

    const [eventSchedules, setEventSchedules] = React.useState([]);

    const fetchEffectiveEventSchedules = function () {
        new ApiFetch()
            .get(EFFECTIVE_PERFORMANCE_EVENT_SCHEDULES_RELATIVE_URL)
            .then((response) => {
                const responseBody = ApiFetch.getResponseBody(response);

                if (responseBody && responseBody.data) {
                    setEventSchedules(responseBody.data);
                } else {
                    setEventSchedules([]);
                }
            });
    }

    const initEffect = function () {
        fetchEffectiveEventSchedules();

        if (Objects.isNull(interval)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            interval = setInterval(fetchEffectiveEventSchedules, 30000);
        }
    }

    React.useEffect(initEffect, []);

    return (
        <React.Fragment>
            <div id="performance-event-schedules">
                <HeaderDivider
                    title="What's coming up?"
                    description="Check out when to tune along to the movement of great vibes!"
                    iconStyle="broadcast" />

                <div className="container-fluid so-white-pattern-bg text-dark" style={{maxWidth: "1600px"}}>
                    <EmptyDivider />

                    <div className="d-block pe-5 ps-5">
                        {Arrays.hasItems(eventSchedules) && eventSchedules.map((eventSchedule) => {
                            return (
                                <EventScheduleDetailsPresenter key={eventSchedule.id}
                                                               eventSchedule={eventSchedule}/>
                            )
                        })}
                    </div>

                    <EmptyDivider degree={2}/>
                </div>
            </div>
        </React.Fragment>
    )
}