
export default class Objects {

    static isNull(object) {
        return object === null || (typeof object === 'undefined');
    }

    static isNotNull(object) {
        return object !== null && (typeof object !== 'undefined');
    }

    static isFunction(object) {
        return Objects.isNotNull(object) && (typeof object === 'function');
    }
}