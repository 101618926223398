
export const WebLinkCollection = {
    emailAddress: "mailto:tsepomaleka@gmail.com",
    facebookPage: "https://facebook.com/PurpleCarbon",
    discordCommunity: "https://discord.gg/88AWNUhzv",
    secondLifeGroup: "secondlife:///app/group/28088e7f-7394-c547-44c9-f51926eeb980/about",
    tiktokLivePage: "https://www.tiktok.com/@mystical_nerd/live",
    applePodcastsEmbedSource: "https://embed.podcasts.apple.com/us/podcast/chemical-reactions-mix-by-purple-carbon/id1550798605",
    mixCloudPage: "https://www.mixcloud.com/tsepo_mngomezulu",
    whatsAppCommunityGroup: "https://chat.whatsapp.com/ClLWBl4nhTmKPCRjxCZneP",
    youTubeChannel: "https://www.youtube.com/@DJTsepoMngomezulu",
    shoutcastStream: "https://listen.purplecarbon.africa/;stream"
}