import React from "react";
import Strings from "../utils/Strings";

const ICON_STYLE_FORMAT = "bi bi-{0} {1} social-media-icon";

export default function SocialMediaIcon({ url, type, title, darkMode = false }) {

    const getIconStyle = function (type, darkMode) {
        return Strings.format(ICON_STYLE_FORMAT, type, (darkMode ? "text-dark" : "text-white"));
    }

    return (
        <React.Fragment>
            <a href={url} title={title} target="_blank"
               rel="noreferrer" className="btn jiggle-animation">
                <span className={getIconStyle(type, darkMode)} />
            </a>
        </React.Fragment>
    )
}