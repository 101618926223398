import Objects from "./Objects";

export default class Strings {

    static isNotBlank(value) {
        return value !== null && value !== undefined && value.trim().length > 0;
    }

    static isBlank(value) {
        return Objects.isNull(value) || value.trim().length === 0;
    }

    static format(template, ...values) {
        return template.replace(/{(\d+)}/g, (match, index) => {
            return typeof values[index] !== 'undefined' ? values[index] : match;
        });
    }
}